/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React, { useRef, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import idx from 'idx'
import mapValues from 'lodash/mapValues'
import FocusLock from 'react-focus-lock'
import groupBy from 'lodash/groupBy'
import { theme } from '../../styles'

import CrossIcon from '../../../static/icons/misc-icons/close-icon.svg'
import downChevronGray300 from '../../../static/icons/arrow-icons/chevron-icon-gray300.svg'
import Text from '../common/Text'
import Button from '../common/Button'
import TextLink from '../common/TextLink'
import rightArrowBlack from '../../../static/icons/arrow-icons/right-arrow-icon-black.svg'
import rightArrowWhite from '../../../static/icons/arrow-icons/right-arrow-icon-white.svg'
import getImage from '../../utils/getImage'
import SubmenuCta from './SubmenuCta'
import { trackEvent } from '../../utils/eventTrack'
import { useUserLang } from '../../context/UserLangContext'
import generateAriaLabelTranslations from '../../data/generateAriaLabelTranslations'

const Wrapper = styled.div`
  padding: 32px;
  background-color: ${theme.color.white};
  border-radius: 8px;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: auto auto;
  grid-column-gap: 40px;
  top: 46px;
  left: 0;
  right: 0;
  bottom: 0;
  height: fit-content;
  width: fit-content;
  min-width: 500px;
  position: absolute;
  transition: 200ms;
  transition-property: transform, opacity, height;
  transform-origin: 50% -50px;

  @media (min-width: 1000px) and (max-width: 1500px) {
    ${props =>
      props.noSubmenuCta ? 'grid-template-columns: repeat(2, 1fr);' : 'grid-template-columns: repeat(3, 1fr);'}
    ${props => (props.noSubmenuCta ? 'grid-row-gap: 0px;' : 'grid-row-gap: 32px;')}
    grid-column-gap: 32px;
    left: ${props => (props.submenuTitle === 'company' || props.submenuTitle === 'resources' ? '-100px' : '0')};
  }

  @media (min-width: 1501px) {
    left: -100px;
  }

  ${p =>
    p.isSubMenuOpen
      ? `
      z-index: 99999;
      opacity: 1;
      box-shadow: 0 50px 100px 5px rgba(50, 50, 93, 0.25), 0 30px 60px -10px rgba(0, 0, 0, 0.3);
      transform: translateX(0%) rotateX(0deg);
      pointer-events: auto;
  `
      : `
      z-index: -9999;
      opacity: 0;
      transform: translateX(0%) rotateX(-15deg);
      pointer-events: none;
  `};

  @media (max-width: 999px) {
    position: absolute;
    margin: 16px;
    grid-auto-flow: row;
    grid-template-rows: auto;
    grid-column-gap: 0px;
    grid-row-gap: 16px;
    min-width: unset;
    padding: 0px;
    transform-origin: 100% 0;
    width: calc(100% - 32px);
    top: 0;
    transform: translateX(0%) rotateX(0deg);

    ${p =>
      p.isSubMenuOpen
        ? `
      transform: scale(1);
  `
        : `
      transform: scale(0.90);
  `};
  }
`

const CardArrow = styled.div`
  position: absolute;
  left: 24px;
  width: 18px;
  height: 18px;
  transform: translateY(-9px) translateX(50%) rotate(45deg);
  border-radius: 3px 0 0 0;
  background-color: ${theme.color.white};
  box-shadow: -3px -3px 4px rgba(145, 140, 151, 0.1);

  @media (min-width: 1000px) and (max-width: 1500px) {
    left: ${props => (props.submenuTitle === 'company' || props.submenuTitle === 'resources' ? '124px' : '24px')};
  }

  @media (min-width: 1501px) {
    left: 124px;
  }
`

const ColWrapper = styled.section`
  grid-row: 1 / 2;

  @media (max-width: 999px) {
    grid-row: auto;
    grid-column: 1 / 2;
    padding: 0px 24px;
    padding-top: 0px;
  }
`

const SubMenuList = styled.ul`
  transition: all 50ms ease-in-out;
  display: grid;
  gap: 12px;

  @media (max-width: 999px) {
    grid-auto-flow: row;
    grid-auto-columns: 1fr;

    .section-header {
      border-bottom: 1px solid ${theme.color.offWhite};
      padding: 8px 0px 16px 0px;
    }
  }

  .header-text-link a {
    text-decoration: none !important;

    span {
      white-space: nowrap;
    }
    @media (max-width: 800px) {
      span {
        &:hover {
          color: ${theme.color.offBlack}!important;
        }
      }
    }
  }
`

const CloseButton = styled.button`
  padding: 4px;
  background: none;
  align-self: flex-end;
`

const CloseIcon = styled.img`
  width: 14px;
`

const HeaderListItem = styled.li`
  position: relative;
  margin-bottom: 0px;
  display: flex;

  a:not(.button) {
    padding: 0px 28px 0px 0px !important;

    ::before {
      opacity: 0;
      content: '';
      position: absolute;
      background: url(${rightArrowBlack});
      background-position: center;
      background-repeat: no-repeat;
      top: 0;
      bottom: 0;
      right: 0;
      left: 100%;
      width: 20px;
      margin-top: 1px;
    }

    :hover {
      ::before {
        background: url(${rightArrowWhite});
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    :focus,
    :hover {
      padding: 0px 28px 0px 0px;

      ::before {
        width: 28px;
        opacity: 1;
        left: unset;
      }
    }
  }
  @media (max-width: 999px) {
    margin-bottom: 0px;
  }
`

const SubmenuTextLink = styled(TextLink)`
  text-decoration: none !important;
  color: ${theme.color.offBlack}!important;
  z-index: 0;
`

const LinkIcon = styled.img`
  margin-right: 16px;
  width: 18px;
  height: auto;
`

const MobFooterWrapper = styled.section`
  border-radius: 0px 0px 8px 8px;
  padding: 24px;
  padding-top: 16px;
  display: grid;
  gap: 16px;
  background-color: ${theme.color.offWhite};

  a {
    text-decoration: none !important;
    width: fit-content;

    :hover {
      span:not(.external-link) {
        color: white;
      }
    }
  }
`

const MobSubMenuHeaderWrapper = styled.section`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 24px;
`

const SubMenuHeaderButton = styled.button`
  background-color: transparent;
  padding: 0px;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    padding: 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${downChevronGray300});
    background-size: 14px;
    width: 14px;
    height: 14px;
    margin-right: 12px;
    margin-left: -3px;
    transform: rotate(90deg);
  }
`

const LinkCta = styled(Button)`
  @media (max-width: 1050px) {
    width: fit-content !important;
    margin: 8px 0 !important;
  }
`

const CloseButtonAlt = styled.button`
  padding: 0;
  height: 14px;
  width: 14px;
  background: none;
  align-self: flex-end;
  position: absolute;
  top: 12px;
  right: 12px;
  opacity: 0;
  pointer-events: none;
  cursor: default;

  :focus {
    opacity: 1;
    border-radius: 2px;
    outline: 3px solid ${theme.color.purple500};
  }
`
const CloseIconAlt = styled.img`
  width: 14px;
  height: 14px;
  position: relative;
  top: -7px;
`

const MobSubMenuHeader = ({ setIsOpen, toggleOpen, ariaLabels }) => (
  <MobSubMenuHeaderWrapper>
    <SubMenuHeaderButton aria-label={ariaLabels?.back} onClick={() => toggleOpen()}>
      Back
    </SubMenuHeaderButton>
    <CloseButton role="button" aria-label={ariaLabels?.closeMenu} onClick={() => setIsOpen(false)}>
      <CloseIcon alt="" src={CrossIcon} />
    </CloseButton>
  </MobSubMenuHeaderWrapper>
)

const SubMenu = ({ textLinks, isSubMenuOpen, toggleOpen, mobile, setIsOpen, showCTA, showSecondaryCTA }) => {
  const [submenuTitle, setSubmenuTitle] = useState()
  const [utmParams, setUtmParams] = useState(null)
  const submenuTitleListRef = useRef(null)
  const { userLang } = useUserLang()
  const ariaLabels = generateAriaLabelTranslations({ userLang })

  const handleKeyDown = (e, firstListItem, lastListItem) => {
    if (e.key === 'ArrowUp') {
      if (e.target?.parentElement?.previousElementSibling?.children) {
        const elementCount = e.target.parentElement.previousElementSibling.children.length
        if (elementCount === 1) e.target.parentElement.previousElementSibling.children?.[0]?.focus()
        if (elementCount === 2) e.target.parentElement.previousElementSibling.children?.[1]?.focus()
      }
      if (firstListItem) toggleOpen(true)
    }
    if (e.key === 'ArrowDown') {
      if (!lastListItem && e.target?.parentElement?.nextElementSibling?.children) {
        const elementCount = e.target.parentElement.nextElementSibling.children.length
        if (elementCount === 1) e.target.parentElement.nextElementSibling.children?.[0]?.focus()
        if (elementCount === 2) e.target.parentElement.nextElementSibling.children?.[1]?.focus()
      }
      if (lastListItem) toggleOpen(true)
    }
  }

  const outsideClickListener = ({ target }) => {
    if (!target.closest('.submenu-wrapper') && isSubMenuOpen) toggleOpen(true)
  }

  useEffect(() => setUtmParams(JSON.parse(sessionStorage.getItem('utm_params'))), [])

  useEffect(() => {
    if (!mobile) {
      const htmlEl = document.querySelector('html')
      setTimeout(() => {
        isSubMenuOpen ? htmlEl?.classList.add('no-scroll') : htmlEl?.classList.remove('no-scroll')
      }, 25)
    }
  }, [isSubMenuOpen])

  useEffect(() => {
    if (isSubMenuOpen) {
      setTimeout(() => {
        document.addEventListener('click', outsideClickListener)
      }, 25)
    }
    return function cleanup() {
      document.removeEventListener('click', outsideClickListener)
    }
  }, [isSubMenuOpen])

  const submenuColumns = mapValues(groupBy(textLinks, 'column'))
  const submenuColumnArr = Object.entries(submenuColumns)

  useEffect(() => {
    submenuColumnArr.map(submenu => setSubmenuTitle(submenu[0].split('-')[0]))
  }, [submenuTitle])

  const noSubmenuCta = submenuTitle === 'company' || submenuTitle === 'community'

  return (
    <FocusLock disabled={!isSubMenuOpen} returnFocus>
      <Wrapper
        isSubMenuOpen={isSubMenuOpen}
        onKeyDown={e => {
          if (e.key === 'Escape') toggleOpen()
        }}
        ref={submenuTitleListRef}
        className={`${submenuTitle} submenu-wrapper`}
        id={`${submenuTitle}-submenu`}
        hidden={!isSubMenuOpen}
        aria-labelledby={`${submenuTitle}-button`}
        noSubmenuCta={noSubmenuCta}
        submenuTitle={submenuTitle}
      >
        {!mobile && <CardArrow submenuTitle={submenuTitle} />}
        {mobile && <MobSubMenuHeader ariaLabels={ariaLabels} setIsOpen={setIsOpen} toggleOpen={toggleOpen} />}
        {submenuColumnArr.map((column, index) => (
          <ColWrapper key={`${column[1][0]?.top_level_title}-${index}`}>
            <SubMenuList isSubMenuOpen={isSubMenuOpen}>
              {column[1].map((textLink, sectionIndex) => {
                const subMenuItemLink = idx(textLink, _ => _.url.url) || idx(textLink, _ => _.fallback_url)
                const isOutBoundLink = subMenuItemLink?.includes('http')
                const listLength = column[1].length
                const firstListItem = index === 0 && sectionIndex === 0
                const submenuColumnLength = submenuColumnArr.length
                const lastListItem = index === submenuColumnLength - 1 && sectionIndex === listLength - 1
                const isSectionHeader = textLink?.section_header

                return (
                  <React.Fragment key={`${textLink?.top_level_title}-${index}-${sectionIndex}`}>
                    {isSectionHeader ? (
                      <HeaderListItem className="header-text-link section-header">
                        {textLink.url.url ? (
                          <Button
                            text={textLink.top_level_title}
                            to={subMenuItemLink}
                            buttonType="link CTA"
                            onKeyDown={e => handleKeyDown(e, firstListItem, lastListItem)}
                            tabIndex={isSubMenuOpen ? 0 : -1}
                            onClick={() => {
                              trackEvent('Navigation Link Clicked', {
                                'Link Text': textLink?.top_level_title,
                                Destination: subMenuItemLink,
                              })
                            }}
                          />
                        ) : (
                          <Text
                            smallBodyBold
                            color="grayNewDesign"
                            style={{ textTransform: 'uppercase', letterSpacing: '.02rem' }}
                          >
                            {textLink.top_level_title}
                          </Text>
                        )}
                      </HeaderListItem>
                    ) : (
                      <HeaderListItem className="header-text-link">
                        {isSubMenuOpen && getImage(textLink.icon, LinkIcon)}
                        <SubmenuTextLink
                          font="smallBodyMedium"
                          color="offBlack"
                          text={textLink?.top_level_title}
                          to={subMenuItemLink}
                          tabIndex={isSubMenuOpen ? 0 : -1}
                          data-cy={idx(textLink, _ => _.top_level_title)
                            .toString()
                            .replace(/ /g, '-')
                            .toLowerCase()}
                          onKeyDown={e => handleKeyDown(e, firstListItem, lastListItem)}
                          outboundHeaderLink={isOutBoundLink}
                          anchorClassName="submenu-text-link"
                          onClick={() => {
                            trackEvent('Navigation Link Clicked', {
                              'Link Text': textLink?.top_level_title,
                              Destination: subMenuItemLink,
                            })
                          }}
                        />
                      </HeaderListItem>
                    )}
                  </React.Fragment>
                )
              })}
            </SubMenuList>
          </ColWrapper>
        ))}
        <SubmenuCta submenuTitle={submenuTitle} isSubMenuOpen={isSubMenuOpen} toggleOpen={toggleOpen} />
        {mobile && (
          <MobFooterWrapper>
            <LinkCta
              text="Login"
              to={process.env.GATSBY_AEYE_PORTAL_LOGIN_URL}
              tabIndex={isSubMenuOpen ? 0 : -1}
              buttonType="link CTA"
              loginLink
            />
            {showSecondaryCTA && (
              <Button
                text="Start Free Trial"
                to="#get-started-free"
                size="small"
                buttonType="secondary"
                tabIndex={isSubMenuOpen ? 0 : -1}
              />
            )}
            {showCTA && utmParams?.utm_campaign !== 'toolbar_referral' && (
              <Button text="Schedule Demo" to="#schedule-demo" size="small" tabIndex={isSubMenuOpen ? 0 : -1} />
            )}
          </MobFooterWrapper>
        )}
        {!mobile && (
          <CloseButtonAlt role="button" aria-label={ariaLabels?.closeMenu} onClick={() => toggleOpen(true)}>
            <CloseIconAlt alt="" src={CrossIcon} />
          </CloseButtonAlt>
        )}
      </Wrapper>
    </FocusLock>
  )
}

export default SubMenu
